<template>
  <div class="container">
    <Navbar navTitle="DEALER ADMIN"></Navbar>
    <main>
      <Nav></Nav>
      <div class="mainBox">
        <van-cell-group>
          <van-cell title="已质押DPR数量" :value="haveStakingAmount">
          </van-cell>
          <van-cell title="已获得质押码数量" :value="haveCodeAmount">
          </van-cell>
          <van-cell title="已生成质押码" :value="codeList.length">
          </van-cell>
          <van-cell title="还可生成质押码">
            <div slot="default">
              <span v-if="haveCodeAmount - codeList.length >= 0">{{haveCodeAmount - codeList.length}}</span>
            </div>
          </van-cell>
        </van-cell-group>
        <div style="padding: 30px;">
          <van-button :disabled="haveCodeAmount - codeList.length <= 0" :loading="createCodeLoading" block type="primary" @click="createCode">生成质押码</van-button>
        </div>
      </div>
      <div style="background-color: #fff; padding: 30px;">
        <h3 style="text-align: center">质押码列表</h3>
        <div class="tableBox">
          <table>
            <thead>
              <tr>
                <th>质押码编号</th>
                <th>生成时间</th>
                <th>绑定状态</th>
                <th>绑定用户</th>
                <th>绑定时间</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in showList" :key="item._id">
                <td>{{item._id}}</td>
                <td>{{$moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}}</td>
                <td>
                  <van-tag type="success" v-if="!!item.user">已绑定</van-tag>
                  <van-tag type="primary" v-else>未绑定</van-tag>
                </td>
                <td>{{item.user}}</td>
                <td>
                  <span v-if="item.bindingTime">
                    {{$moment(item.bindingTime/1).format('YYYY-MM-DD HH:mm:ss')}}
                  </span>
                </td>
                <td>
                  <van-button plain size="small" @click="downloadQRCode(item)" type="info">下载二维码</van-button>
                </td>
              </tr>
            </tbody>
          </table>
          <div style="padding: 20px 0;">
            <van-pagination mode="simple" v-model="currentPage" :total-items="codeList.length" :items-per-page="pageSize" />
          </div>
        </div>
      </div>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '@/components/Footer/Footer'
import Nav from './Nav.vue'
import QRCode from 'qrcode'
import Navbar from '@/components/Navbar/NavbarDealer'

function downloadImg(imgUrl, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    var bstr = atob(imgUrl.split(',')[1])
    var n = bstr.length
    var u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    var blob = new Blob([u8arr])
    window.navigator.msSaveOrOpenBlob(blob, filename + '.' + 'png')
  } else {
    // 这里就按照chrome等新版浏览器来处理
    const a = document.createElement('a')
    a.href = imgUrl
    a.setAttribute('download', filename)
    a.click()
  }
}

export default {
  components: {
    Footer,
    Nav,
    Navbar
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 5,
      haveStakingAmount: '',
      haveCodeAmount: '',
      codeList: [],
      createCodeLoading: false
    }
  },
  computed: {
    showList() {
      let codeList = this.codeList.sort((a,b) => {
         if (b.bindingTime != a.bindingTime) {
           return b.bindingTime - a.bindingTime
         }
         return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      })
      let showList = codeList.slice((this.currentPage-1)*this.pageSize, (this.currentPage)*this.pageSize);
      return showList
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.getCodeList()
      this.getStaking();
    },
    getUserData() {
      let tokenData = JSON.parse(window.localStorage.getItem("dealerTokenData"))
      this.userData = {
        erc20: tokenData.walletAddress,
        id: tokenData.dealer
      }
    },
    async getStaking() {
      this.$fetch({
        headers: {
          Authorization: window.localStorage.getItem('dealerToken')
        },
        url: '/dealer/stakingData',
      }).then(res => {
        this.haveStakingAmount = (res.data.dprAmount/1e18).toFixed(5)/1;
        this.haveCodeAmount = parseInt((this.haveStakingAmount/2000).toFixed(5)/1)
        console.log(res)
      })
    },
    createCode() {
      this.createCodeLoading = true
      this.$fetch({
        headers: {
          Authorization: window.localStorage.getItem('dealerToken')
        },
        url: '/dealer/createStakingCode',
        method: 'POST'
      }).then(res => {
        if (res.success) {
          this.$toast.success();
          this.getCodeList()
        }
      }).finally(() => {
        this.createCodeLoading = false
      })
    },
    getCodeList() {
      this.$fetch({
        headers: {
          Authorization: window.localStorage.getItem('dealerToken')
        },
        url: '/dealer/stakingCode',
      }).then(res => {
        console.log(res)
        if (res.success) {
          this.codeList = res.data;
        }
      })
    },
    downloadQRCode(item) {
      QRCode.toDataURL(`${window.location.origin}/bindCode?code=${item._id}`)
      .then(url => {
        downloadImg(url, item._id)
      })
      .catch(err => {
        console.error(err)
      })
    }
  }
  
}
</script>
<style>
html, body, #app {
  height: 100%
}
</style>
<style lang="less" scoped>

.container {
  min-height: 100%;
  background: #001938;
  .mainBox {
    max-width: 600px;
    margin: 30px auto;
    padding: 30px;
    border-radius: 30px;
    background: #fff;
  }
  .tableBox {
    font-size: 12px;
    text-align: center;
    table {
      width: 100%;
    }
    
  }
}

</style>
