<template>
  <div class="navContainer">
    <!-- <a href="./admin"><van-button type="info">个人中心</van-button></a> -->
    <a href="./stake"><van-button type="info">质押</van-button></a>
    <a href="./stakingCode"><van-button type="info">质押码管理</van-button></a>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.navContainer {
  display: flex;
  justify-content: center;
  a {
    margin: 0 10px;
  }
}
</style>x